/* Back button */
.btn-back-review {
    color: #d40511;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.back-icon {
    width: 0.6rem;
    margin-top: -0.1rem;
}

.consent-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.consent-text a {
    color: #d40511;
    text-decoration: none;
    font-weight: 400;
}

.consent-text a:hover {
    color: #d40511;
}

.review-spinner {
    height: 100vh;
}

.review-spinner .text-secondary {
    padding: 25px;
}

.consent-text .legal-id-mandatory {
    padding: 0 3px 0 0;
}
