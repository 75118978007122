.carousel-item {
    margin-left: 0 !important;
}

.carousel-indicators {
    position: relative !important;
    order: 3;
}

.carousel-indicators .active {
    background-color:  #D40511 !important;
}

.carousel-indicators button {
    border: none !important;
    border-radius: 50% !important;
    background-color:  #8C8C8C !important;
    width: 17px !important;
    height: 17px !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.carousel {
    display: flex;
    flex-direction: column;
}