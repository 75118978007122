.custom-tooltip .tooltip-inner {
    text-align: start;
    padding: 0.75rem;
}

.custom-tooltip .tooltip-inner {
    max-width: 320px;
}

.custom-tooltip .tooltip-inner ul {
    margin: 5px 0 0;
}
