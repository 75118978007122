.spinner-card {
  border-radius: 12px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  color: #000000;
  line-height: 1.75rem;
  padding: 2.5rem;
  text-align: center;
}

.spinner-title {
  font-weight: 700;
  line-height: 1.75rem;
}

.spinner-img {
  margin: 0.85rem 0;
}

.spinner-text {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.onespan-card {
  padding: 12rem 0;
}

.shipping-rates-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.btn-link.shipping-rates-footer-link {
  color: #D40511;
  text-decoration: none;
  margin-right: 24px;
}

.btn-link.shipping-rates-footer-link:hover {
  color: #D40511;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.shipping-rates-container {
  border-radius: 12px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  padding: 0 !important;
}

.shipping-message {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.shipping-spinner {
  display: flex;
  justify-content: center;
}

.shipping-spinner-text {
  margin-right: 5px;
}

.shipping-preferred-button {
  display: flex;
  justify-content: flex-end;
}

.shipping-download-docs {
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
  border-bottom: 1px solid #cccccc;
}

.shipping-preferred-section {
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
}

.shipping-preferred-section h6 {
  font-size: 18px;
}
