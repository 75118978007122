/* Stepper */

#RFS-ConnectorContainer{
  left : calc((-50% + 2.125em) - 15px);
  width:calc(100% - 39px);
  top:calc((2.125em - -6px) / 2)
}
#RFS-StepperContainer{
  padding: 24px 17%;
}
.step-btn {
  border: 2px solid #8C8C8C !important;
  font-weight: 700;
  pointer-events: none;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}
.step-btn.completed {
  border: 3px solid #76BD22 !important;
  font-weight: 700;
  color:#fff
}
.step-btn.active {
  border: 3px solid #000000 !important;
  font-weight: 700;
}
.step-btn ~ #RFS-LabelContainer #RFS-Label {
  font-size: 0.8rem;
  color: #4f4f4f;
}
.step-btn.completed ~ #RFS-LabelContainer #RFS-Label {
  font-size: 0.8rem;
  color: #000000;
  font-weight: 400;
}
.step-btn.active ~ #RFS-LabelContainer #RFS-Label {
  font-size: 0.8rem;
  color: #000000;
  font-weight: 700;
}
.step-btn ~ #RFS-LabelContainer .step-subtitle {
  font-size: 0.8rem;
  color: #7f7f7f;
}
.step-btn.completed ~ #RFS-LabelContainer .step-subtitle {
  font-size: 0.8rem;
  color: #000000;
}
.step-btn.active ~ #RFS-LabelContainer .step-subtitle {
  font-size: 0.8rem;
  color: #7f7f7f;
}
/* Stepper end */
