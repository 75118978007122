.faq-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6rem 8.5rem;
}

.faq-banner-title {
  color: #ffffff;
  font-size: 2.75rem;
  font-weight: 800;
  line-height: 2.75rem;
  margin-bottom: 1rem;
}

.faq-banner-subtitle {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 0;
}

.faq-main {
  background-color: #f7f7f7;
  padding: 3rem 0;
}

.faq-main .accordion-item {
  background-color: #f7f7f7;
}

.faq-main-content {
  width: 65%;
  margin: 0 auto;
}

.faq-contact {
  padding: 3rem 0;
}

.faq-contact-content {
  column-gap: 1.875rem;
  width: 65%;
  margin: 0 auto;
}

.faq-contact-title {
  color: #1a1a1a;
  font-size: 1.875rem;
  font-stretch: condensed;
  font-weight: 800;
  line-height: 2.75rem;
  margin-bottom: 1rem;
}

.faq-contact-subtitle {
  color: #1a1a1a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
}

.faq-contact-btn {
  line-height: 1.75rem !important;
  padding: 0.625rem 2.5rem !important;
}
