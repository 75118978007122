/* Checkbox */
.custom-checkbox[type='checkbox'] {
  height: 1.25rem;
  width: 1.25rem;
}
.custom-checkbox:focus[type='checkbox'] {
  box-shadow: none;
  border: 2px solid #000000;
}
.custom-checkbox:checked[type='checkbox'] {
  border-color: #000000;
  background-image: url(../../../assets/icons/checkmark.svg);
}

.form-check-label {
  margin-left: 10px;
  color: #000000;
  margin-top: 3px;
  width: 100%;
}

.form-check-label .legal-id-mandatory {
  padding: 0 3px 0 0;
}

.form-check .legal-id-mandatory {
  padding: 0 3px 0 0;
}
/* Checkbox end */
