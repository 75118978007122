/* Pristine */
.form-floating .form-control.custom-text-field {
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
  border: 1px solid #8c8c8c;
  color: #000000;
  height: calc(3rem + 2px);
  padding: 0.75rem;
  padding-right: calc(1.5em + 0.75rem);
}
.form-floating .form-control.custom-text-field:hover {
  border-color: #1a1a1a;
}
.form-floating .form-control.custom-text-field:focus {
  border: 1.5px solid #1a1a1a;
  color: #000000;
  box-shadow: none;
}
.form-floating .form-control.custom-text-field:focus,
.form-floating .form-control.custom-text-field:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.425rem;
}
.form-floating .form-control.custom-text-field ~ label {
  color: #666666;
  padding: 0.75rem;
}
.form-floating .form-control.custom-text-field:focus ~ label,
.form-floating .form-control.custom-text-field:not(:placeholder-shown) ~ label {
  opacity: 1;
  transform: scale(0.6875) translateX(0.45rem) translateY(-0.25rem);
}

/* Loader */
.form-floating .form-control.custom-text-field.has-loader {
  background-image: url('../../../assets/icons/spinner.gif');
}

/* Valid */
.form-control.custom-text-field.is-valid,
.was-validated .form-control.custom-text-field:valid {
  z-index: 10000;
  border-color: #8c8c8c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('../../../assets/icons/validated_success.svg');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: 1.5rem;
}
.form-control.custom-text-field.is-valid:hover,
.was-validated .form-control.custom-text-field:valid:hover {
  border-color: #1a1a1a;
}
.form-control.custom-text-field.is-valid:focus,
.was-validated .form-control.custom-text-field:valid:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}

/* Invalid */
.form-control.custom-text-field.is-invalid,
.was-validated .form-control.custom-text-field:invalid {
  border-color: #d40511;
  background-image: none;
}
.form-control.custom-text-field.is-invalid:hover,
.was-validated .form-control.custom-text-field:invalid:hover {
  border-color: #1a1a1a;
}
.form-control.custom-text-field.is-invalid:focus,
.was-validated .form-control.custom-text-field:invalid:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}
.form-floating .form-control.custom-text-field.is-invalid:focus ~ label,
.form-floating .form-control.custom-text-field.is-invalid:not(:placeholder-shown) ~ label {
  color: #d40511;
}
.form-floating .form-control.custom-text-field ~ .invalid-feedback {
  padding-left: 1.531rem;
  background-image: url('../../../assets/icons/validated_error.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 0.938rem;
}

/* Disabled */
.form-floating .form-control.custom-text-field:disabled {
  background-color: #d1d1d1;
  border-color: #8c8c8c;
  color: #000000;
  opacity: 0.5;
}
.form-floating .form-floating .form-control.custom-text-field:disabled ~ label {
  opacity: 0.5;
}

.field-label > img {
  pointer-events: initial !important;
  cursor: pointer;
  margin: 3px 0 0 9px;
  vertical-align: top;
}

.legal-id-code {
  max-width: calc((85% - 10px));
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.legal-id-mandatory {
  display: inline-block;
  vertical-align: top;
  color: #dc3545;
  font-weight: bold;
  padding-left: 3px;
}

.form-floating .invalid-feedback.direction-ltr {
  direction: ltr;
  text-align: right;
}
