.edit-icon {
    width: 1rem;
}

.edit-btn {
    position: absolute;
    right: -1rem;
    top: -1rem;
}

.card-icon {
    line-height: 2.1875rem;
}

.name-text {
    font-stretch: condensed;
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.1875rem;
    text-transform: uppercase;
    color: #000000;
    margin-left: 0.75rem;
    margin-right: 1rem;
}

.country-text {
    background: #F2F2F2;
    border-radius: 4px;
    color: #666666;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    margin-top: 3px;
    padding: 0 15px;
    width: fit-content;
    height: fit-content;
}

.padding-03 {
    padding-top: 0.3rem;
}

.company-p {
    padding-left: 1.5rem !important;
    padding-top: 1rem;
}

.web-link {
    color: #D40511 !important;
}

.optional-header-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #666666;
    margin-bottom: 0.5rem;
}

.optional-p {
    padding-left: 1.5rem !important;
}

.optional-p1 {
    padding-left: 1.5rem !important;
    padding-bottom: 1rem !important;
}

.address-p1 {
    padding-left: 1.5rem !important;
}

.opt-pnl-l {
    background-color: #CCCCCC;
    height: 1px;
    margin: 1.5rem 0;
}

.panel-inline {
    inline-size: 25rem;
    width: 60% !important;
}

.panel-row:not(:first-of-type) {
    margin-top: 1rem;
}

.company-fileupload:last-child {
    margin-bottom: 0;
}

.panel-card {
    box-shadow: none;
}

.panel-main-text {
    margin-left: 0.75rem;
    color: #000000;
}
