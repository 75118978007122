.page-title {
    font-size: 2.75rem;
    font-stretch: condensed;
    font-weight: 800;
    color: #000000;
}

.page-subtitle {
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    max-width: 90%;
}
