.navbar.header-navbar {
  background-image: linear-gradient(90deg, #ffcc00 0%, #fff0b1 95%);
  padding: 0.5rem 7.375rem;
}

.navbar.header-navbar .header-container {
  display: block;
  flex-wrap: nowrap;
  align-items: normal;
  justify-content: normal;
  width:100%;
}

.navbar.header-navbar .header-content {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar.header-navbar .navbar-brand {
  color: #d40511;
  font-weight: 800;
  font-size: 1.125rem;
}

.navbar.header-navbar .navbar-brand:focus,
.navbar.header-navbar .navbar-brand:hover {
  color: #d40511;
  font-weight: 800;
}

.navbar.header-navbar .nav-link:hover {
  color: #d40511;
}

.navbar.header-navbar .nav-link.selected {
  font-weight: bold;
  cursor: default;
}

.navbar.header-navbar .nav-link.selected:hover {
  color: #000000;
}

.navbar.header-navbar .nav-link.active {
  color: #000000;
  line-height: 1.75rem;
}

.navbar.header-navbar .nav-link {
  color:#000000;
  line-height: 1.75rem;
  text-transform: uppercase;
}
