/* Light */
@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 200;
  src:
    url('WOFF2/Delivery_W_Lt.woff2') format('woff2'),
    url('WOFF/Delivery_W_Lt.woff') format('woff'),
    url('EOT/Delivery_W_Lt.eot') format('embedded-opentype');
}

/* Light Italic */
@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 200;
  src:
    url('WOFF2/Delivery_W_LtIt.woff2') format('woff2'),
    url('WOFF/Delivery_W_LtIt.woff') format('woff'),
    url('EOT/Delivery_W_LtIt.eot') format('embedded-opentype');
}

/* Regular */
@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 400;
  src:
    url('WOFF2/Delivery_W_Rg.woff2') format('woff2'),
    url('WOFF/Delivery_W_Rg.woff') format('woff'),
    url('EOT/Delivery_W_Rg.eot') format('embedded-opentype');
}

/* Italic */
@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 400;
  src:
    url('WOFF2/Delivery_W_It.woff2') format('woff2'),
    url('WOFF/Delivery_W_It.woff') format('woff'),
    url('EOT/Delivery_W_It.eot') format('embedded-opentype');
}

/* Bold */
@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 700;
  src:
    url('WOFF2/Delivery_W_Bd.woff2') format('woff2'),
    url('WOFF/Delivery_W_Bd.woff') format('woff'),
    url('EOT/Delivery_W_Bd.eot') format('embedded-opentype');
}

/* Bold Italic */
@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 700;
  src:
    url('WOFF2/Delivery_W_BdIt.woff2') format('woff2'),
    url('WOFF/Delivery_W_BdIt.woff') format('woff'),
    url('EOT/Delivery_W_BdIt.eot') format('embedded-opentype');
}

/* Condensed Light */
@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 200;
  font-stretch: condensed;
  src:
    url('WOFF2/Delivery_W_CdLt.woff2') format('woff2'),
    url('WOFF/Delivery_W_CdLt.woff') format('woff'),
    url('EOT/Delivery_W_CdLt.eot') format('embedded-opentype');
}

/* Condensed Black */
@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 800;
  font-stretch: condensed;
  src:
    url('WOFF2/Delivery_W_CdBlk.woff2') format('woff2'),
    url('WOFF/Delivery_W_CdBlk.woff') format('woff'),
    url('EOT/Delivery_W_CdBlk.eot') format('embedded-opentype');
}
