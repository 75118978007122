body {
  font-family: 'Delivery', Arial, sans-serif;
  color: #000000;
}

/* Button */
/* Primary */
.dao-container .btn-primary,
.custom-modal .btn-primary {
  background-color: #d40511e3;
  border-radius: 0.25rem;
  font-weight: 700;
  border: none;
}

.dao-container .btn-primary:hover,
.custom-modal .btn-primary:hover {
  background-color: #fa151f;
}

.dao-container .btn-check:focus+.btn-primary,
.custom-modal .btn-check:focus+.btn-primary,
.dao-container .btn-primary:focus,
.custom-modal .btn-primary:focus {
  background-color: #fa151f;
  box-shadow: none;
  outline: 2px solid #000000;
}

.dao-container .btn-check:active+.btn-primary:focus,
.custom-modal .btn-check:checked+.btn-primary:focus,
.dao-container .btn-primary.active:focus,
.custom-modal .btn-primary:active:focus,
.dao-container .show>.btn-primary.dropdown-toggle:focus,
.custom-modal .show>.btn-primary.dropdown-toggle:focus {
  outline: 2px solid #000000;
  box-shadow: none;
}

.dao-container .btn-primary.disabled,
.custom-modal .btn-primary.disabled,
.dao-container .btn-primary:disabled,
.custom-modal .btn-primary:disabled {
  background-color: #d1d1d1;
  opacity: 1;
}

/* Secondary */
.dao-container .btn-secondary,
.custom-modal .btn-secondary {
  color: #d40511;
  background-color: #fafafa;
  font-weight: 700;
  border: 1px solid #d40511 !important;
}

.dao-container .btn-secondary:hover,
.custom-modal .btn-secondary:hover {
  color: #f91320;
  background-color: #ffffff;
  border-color: #f91320;
  box-shadow: unset !important;
}

.dao-container .btn-check:focus+.btn-secondary,
.custom-modal .btn-check:focus+.btn-secondary,
.dao-container .btn-secondary:focus,
.custom-modal .btn-secondary:focus {
  color: #f91320;
  background-color: #ffffff;
  box-shadow: none;
  outline: 2px solid #f91320;
}

.dao-container .btn-check:active+.btn-secondary:focus,
.custom-modal .btn-check:active+.btn-secondary:focus,
.dao-container .btn-check:checked+.btn-secondary:focus,
.custom-modal .btn-check:checked+.btn-secondary:focus,
.dao-container .btn-secondary.active:focus,
.custom-modal .btn-secondary.active:focus,
.dao-container .btn-secondary:active:focus,
.custom-modal .btn-secondary:active:focus,
.dao-container .show>.btn-secondary.dropdown-toggle:focus,
.custom-modal .show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: 1px solid #d40511e3;
}

.dao-container .btn-secondary.disabled,
.custom-modal .btn-secondary.disabled,
.dao-container .btn-secondary:disabled,
.custom-modal .btn-secondary:disabled {
  border-radius: 3px;
  border: 1px solid #dcdcdc !important;
  background: #ffffff99;
  box-sizing: border-box;
  color: #00000066;
  opacity: 1;
}

.dao-container .btn-secondary-border:focus,
.custom-modal .btn-secondary-border:focus,
.dao-container .btn-secondary-border:active,
.custom-modal .btn-secondary-border:active {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #d40511;
}

/* Back button */
.dao-container .btn-back {
  font-weight: 400;
  color: #d40511;
  cursor: pointer;
}

/* Button end */

/* Checkbox */
.dao-container .custom-checkbox[type='checkbox'] {
  height: 1.25rem;
  width: 1.25rem;
}

.dao-container .custom-checkbox:focus[type='checkbox'] {
  box-shadow: none;
  border: 2px solid #000000;
}

.dao-container .custom-checkbox:checked[type='checkbox'] {
  border-color: #000000;
  background-color: transparent;
  background-image: url(./assets/icons/checkmark.svg);
}

/* Checkbox end */

/* Typeahead end */

/* Not used as of now */
/* Typeahead with Input Group */
/* Pristine */
.dao-container .form-floating.input-group .input-group-text {
  background-color: #ffffff;
}

.dao-container .form-floating.input-group .form-control.custom-typeahead~label {
  left: calc(16px + 1.5rem + 2px);
  /* flag's width + left right padding + left right border*/
  z-index: 3;
}

.dao-container .form-floating.input-group .form-control.custom-typeahead::placeholder {
  z-index: 3;
}

.dao-container .form-floating.input-group~.rbt-input-hint {
  left: calc(16px + 1.5rem + 2px) !important;
  /* flag's width + left right padding + left right border*/
  z-index: 3;
}

.dao-container .typeahead-menu-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.dao-container .typeahead-menu-flag {
  width: 36px;
  height: 27px;
  margin-right: 10px;
}

/* Typeahead with Input Group end*/

.dao-container .form-content {
  padding: 2.5rem;
}

.dao-container .field-row:not(:first-of-type) {
  margin-top: 1.5rem;
}

.outline-0 {
  outline: none;
}

.alert-dismissible .btn-close {
  left: auto;
}

.dao-container .dao-text-right {
  text-align: right;
}

.dao-container .accordion-button::after {
  margin-right: 0;
}

.dao-container .exclusive-mb-2 {
  margin-bottom: 2.5rem;
}

.dao-container .retrieveBtn,
.dao-container .nextBtn {
  text-align: right;
}

.dir-ltr {
  direction: ltr;
}

.mandatory-asterisk {
  color: #dc3545;
  font-weight: bold;
}

.icon-back {
  transform: rotate(180deg);
  margin-right: 0.5rem;
}

.modal-process-text {
  color: #ffcc00;
}

@media (min-width: 320px) and (max-width: 767px) {
  .navbar.header-navbar {
    padding: 0.5rem 1rem;
  }

  .header-navbar .navbar-nav {
    flex-direction: row;
  }

  .header-content .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0 !important;
    font-size: 13px;
  }

  .header-content .nav-link.me-4 {
    margin-left: 0.5rem !important;
  }

  .header-content .navbar-brand {
    margin: 0 !important;
  }

  .header-content .navbar-brand img {
    margin: 0 !important;
  }

  .announcement-box {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .account-banner {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .faq-banner {
    padding: 0;
  }

  .banner-title,
  .faq-banner-title {
    font-size: 30px;
    width: auto;
    line-height: 30px;
  }

  .faq-banner-title {
    padding: 4.5rem 1rem 0 1rem;
  }

  .faq-banner-subtitle {
    padding: 0 1rem 4.5rem 1rem;
  }

  .banner-text {
    width: auto;
  }

  .exclusive-container {
    padding: 3rem 1rem;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .benefits-text {
    font-size: 14px;
  }

  .exclusive-icon {
    width: 74px;
    height: 74px;
  }

  .exclusive-icon img {
    width: 49px;
    height: 49px;
  }

  .exclusive-mb-2 {
    margin-bottom: 1.5rem;
  }

  .exclusive-img {
    margin-bottom: 1.5rem;
  }

  .exclusive-text {
    margin-bottom: 1rem;
  }

  .footer-box {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footer-links.d-flex {
    display: block !important;
  }

  .footer-col-6 {
    flex: 0 0 auto;
    width: 50%;
    margin-bottom: 1.5rem;
  }

  .footer-row-text {
    margin: 0;
  }

  .exclusive-title {
    font-size: 1.5rem;
  }

  #RFS-StepperContainer {
    padding: 0 1.875rem 2rem 1.875rem;
  }

  #RFS-LabelContainer {
    display: none;
  }

  .StepperContainer-0-2-1 .StepContainer-0-2-2 {
    padding-left: 0;
    padding-right: 0;
  }

  .stepper-wrapper .mobile-stepper {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 0.5rem 0;
    font-size: 18px;
    font-weight: 700;
  }

  .dao-container .dao-sub-contianer {
    padding: 0 1.5rem 0 1.5rem;
  }

  .dao-container .dao-sub-contianer .mx-auto.mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .dao-container .form-content {
    padding: 1.5rem 1rem;
  }

  .dao-mobile-next-btn {
    text-align: justify !important;
  }

  .dao-mobile-next-btn .btn {
    width: 100%;
  }

  .country-code .dropdown-menu,
  .dao-container .retrieveBtn .btn,
  .dao-container .nextBtn .btn {
    width: 100%;
  }

  .dao-container .retrieveBtn,
  .dao-container .nextBtn,
  .dao-container .prevBtn {
    text-align: center;
  }

  .shipping-download-docs,
  .shipping-preferred-section {
    padding: 1.5rem 1rem;
  }

  .modal-footer .btn {
    width: 100%;
  }

  .modal-footer .btn:first-child {
    order: 1;
    margin-top: 1rem;
  }

  .alert.alert-popout-success {
    width: auto;
    right: 0 !important;
    left: 0 !important;
    padding: 1rem;
    margin: 1rem 0.5rem;
    top: 2rem;
  }

  .alert.alert-popout-success img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .content-title,
  .page-title,
  .payment-header {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .StepButtonContent-0-2-5,
  .file-upload-link,
  .optional-header-text,
  .contact-field-label,
  .message-text {
    font-size: 14px !important;
  }

  .banner-text,
  .exclusive-text,
  .benefits-title,
  .faq-banner-subtitle,
  .thankyou-conventional-subtitle,
  .faq-contact-subtitle,
  .thankyou-subtitle_2,
  .thankyou-subtitle {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .thankyou-title {
    margin: 1rem auto;
  }

  .name-text {
    font-size: 18px;
    line-height: 18px;
  }

  .faq-main-content {
    width: auto;
  }

  .faq-main {
    padding: 2rem 1rem;
  }

  .faq-contact-content {
    width: auto;
  }

  .faq-contact {
    padding: 2rem 1rem 3rem 1rem;
  }

  .faq-contact-img {
    width: 100%;
  }

  .faq-contact-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .thankyou-conventional-title,
  .thankyou-card-header,
  .thankyou-article-header,
  .thankyou-title {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .thankyou-card-header,
  .thankyou-article-header {
    margin-bottom: 1rem;
  }

  .accordion-button.collapsed span {
    margin-right: 1rem;
  }

  .faq-contact-btn {
    width: 100%;
  }

  .custom-accordion .accordion-button,
  .custom-accordion .accordion-button:focus,
  .custom-accordion .accordion-button:not(.collapsed) {
    padding: 1rem 0rem;
  }

  .custom-accordion .accordion-list:first-child .accordion-button {
    padding: 0 0 1rem 0;
  }

  .custom-accordion .accordion-body {
    padding: 0 2.5rem 1rem 0;
  }

  .card-icon-name {
    order: 1;
    margin-top: 12px;
  }

  .country-text {
    order: 0;
  }

  .contact-panel-row,
  .company-panel-row {
    flex-direction: column;
  }

  .edit-btn {
    right: 0;
    top: 0;
  }

  .shipping-preferred-button {
    flex-direction: column;
  }

  .thankyou-page-section1 {
    margin-top: 2rem;
  }

  .thankyou-conventional-subtitle {
    margin: 1rem auto 2rem;
  }

  .thankyou-page-articles-section {
    padding: 0 1rem !important;
    margin: 1rem 0 2rem 0 !important;
  }

  .dao-thankyou-section {
    padding-bottom: 0.5rem;
  }

  .thankyou-page-services-section {
    padding: 0 1rem !important;
    margin: 1rem 0 0.5rem 0 !important;
  }

  .thankyou-card .rounded {
    margin-bottom: 1.5rem;
  }

  .thankyou-dao-success-img {
    width: 53px;
    height: 53px;
  }

  .thankyou-dao-contactus {
    margin: 1rem auto 1.5rem;
  }

  .thankyou-action-wrap {
    margin: 1.5rem auto;
  }

  .thankyou-action-wrap a,
  .footer-width {
    width: 100%;
  }

  .dao-services-mobile .shadow {
    width: 96%;
    margin-bottom: 1.5rem !important;
    margin: 0 auto;
  }

  .custom-modal.modal-dialog {
    width: auto;
  }

  [dir='rtl'] .edit-btn {
    left: 0;
    right: auto;
  }

  [dir='rtl'] .accordion-button.collapsed span {
    margin-left: 1rem;
    margin-right: 0;
  }

  [dir='rtl'] .header-content .nav-link.me-4 {
    margin-right: 0.5rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .exclusive-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .footer-box {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .account-banner {
    background-size: 1023px 479px;
  }

  .dao-container .dao-sub-contianer {
    padding: 0 1.5rem 0 1.5rem;
  }
}
