.country-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.information-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.address {
  font-weight: 700;
  margin: 0;
}

.address-logo {
  width: 0.5rem;
}

.company-information-label {
  font-weight: 700;
  margin: 0;
}

.billing-same {
  background-image: linear-gradient(90deg, #ffcc00 0%, #fff0b1 95%);
}

.legal-id-label > label {
  pointer-events: initial !important;
}

.company-details {
  border-top: solid 1px #cccccc;
}

.spinner-container {
  display: flex;
  justify-content: center;
}

.company-spinner {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
  margin-top: 2rem;
  text-align: center;
}

.legalIdError {
  padding-left: 1.531rem;
  background-image: url('../../../assets/icons/validated_error.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 0.938rem;
  color: #dc3545;
  margin-top: 4px;
}

.show-doc-example {
  font-size: 16px;
  color: #d40511;
  cursor: pointer;
}

.reg-not-present .form-check-label {
  margin-top: 2px;
}

.doc-sample-img {
  width: 100%;
}
