.footer-box {
  background-color: #f1f1f1;
  padding: 0 7.375rem;
}

.footer-box .container {
  display: flex;
  flex-direction: column;
}

.footer-row-text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin: 0.25rem 2.5rem 0.25rem 0;
  text-decoration: none;
}

button.footer-row-text:hover, a.footer-row-text:hover {
  color: #d40511;
}

.follow-us {
  font-size: 0.875rem;
  font-weight: 700;
}

.media-wrap a {
  display: inline-block;
  width: auto;
  padding: 0;
}

.social-media-img {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.25rem 1rem 0.25rem 0;
}

.footer-links {
  justify-content: space-between;
}

.footer-width {
  width: 75%;
}
