[dir='rtl'] body {
  direction: rtl;
}

[dir='rtl'] [type=email], 
[dir='rtl'] [type=number], 
[dir='rtl'] [type=tel], 
[dir='rtl'] [type=url] {
  direction: rtl;
}

[dir='rtl'] .alert-dismissible .btn-close {
  left: 0;
  right: auto;
}

[dir='rtl'] .social-media-img {
  margin: 0.25rem 0 0.25rem 1rem;
}

[dir='rtl'] .footer-row-text {
  margin: 0 0 0 2.5rem;
}

[dir='rtl'] .col-3.exclusive-icon {
  margin-left: 10px;
  margin-right: 0;
}

[dir='rtl'] #RFS-ConnectorContainer {
  right: calc((-50% + 2.125em) - 15px);
}

[dir='rtl'] .text-end {
  text-align: left !important;
}

[dir='rtl'] .navbar.header-navbar {
  background-image: linear-gradient(90deg, #fff0b1 0%, #ffcc00 95%);
}

[dir='rtl'] .form-floating > .form-select.custom-select-field {
  background-position: left calc(0.375em + 0.1875rem) center;
}

[dir='rtl'] .flag-icon {
  left: auto;
  right: 12px;
}

[dir='rtl'] .flag-label .form-control.custom-typeahead {
  padding-left: 0 !important;
  padding-right: 55px !important;
  text-align: right;
  direction: ltr;
}

[dir='rtl'] .dao-container .typeahead-menu-flag {
  margin-left: 10px;
  margin-right: 0;
}

[dir='rtl'] .rbt-menu {
  inset: 0px 0 auto auto !important;
}

[dir='rtl'] .form-floating > .form-select.custom-select-field,
[dir='rtl'] .form-floating .form-control.custom-text-field {
  padding-left: calc(1.5em + 0.75rem);
  padding-right: 0.75rem;
}

[dir='rtl'] .form-floating .form-select.custom-select-field:focus ~ label,
[dir='rtl'] .form-floating .form-select.custom-select-field:not(:placeholder-shown) ~ label,
[dir='rtl'] .form-floating .form-control.custom-text-field:focus ~ label, 
[dir='rtl'] .form-floating .form-control.custom-text-field:not(:placeholder-shown) ~ label {
  transform: none;
  font-size: 11px;
  top: -7px;
  right: 0;
}

[dir='rtl'] .form-floating .form-control.custom-typeahead:focus ~ label, 
[dir='rtl'] .form-floating .form-control.custom-typeahead:not(:placeholder-shown) ~ label {
  transform: none;
  font-size: 11px;
  top: -7px;
  right: -15px;
}

[dir='rtl'] .form-floating .form-control.custom-text-field ~ .invalid-feedback,
[dir='rtl'] .form-control.custom-typeahead ~ .invalid-feedback {
  background-position: right center;
  padding-left: 0;
  padding-right: 1.531rem;
}

[dir='rtl'] .form-control.custom-text-field.is-valid, 
[dir='rtl'] .was-validated .form-control.custom-text-field:valid {
  background-position: left calc(0.375em + 0.1875rem) center;
}

[dir='rtl'] .form-control.custom-typeahead ~ label {
  padding: 12px 72px 0 0px;
}

[dir='rtl'] .field-label > img {
  margin: 3px 9px 0 0;
}

[dir='rtl'] .form-floating .form-control.custom-text-field {
  background-position: left calc(0.375em + 0.1875rem) center;
}

[dir='rtl'] .form-check-label {
  margin-left: 0;
  margin-right: 10px;
}

[dir='rtl'] .form-check-label .legal-id-mandatory {
  padding: 0 0 0 3px;
}

[dir='rtl'] .dao-container .dao-text-right,
[dir='rtl'] .dao-container .retrieveBtn, 
[dir='rtl'] .dao-container .nextBtn {
  text-align: left;
}

[dir='rtl'] .icon-back {
  transform: rotate(360deg);
  margin-left: 0.5rem;
  margin-right: 0;
}

[dir='rtl'] .shipping-spinner-text {
  margin-left: 5px;
  margin-right: 0;
}

[dir='rtl'] .edit-btn {
  left: -1rem;
  right: auto;
}

[dir='rtl'] .panel-main-text {
  margin-left: 0;
  margin-right: 0.75rem;
}

[dir='rtl'] .form-check {
  padding-left: 0;
  padding-right: 1.5em;
}

[dir='rtl'] .form-check .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

[dir='rtl'] .legalIdError {
  padding-left: 0;
  padding-right: 1.531rem;
  background-position: right center;
}

[dir='rtl'] .btn-link.shipping-rates-footer-link,
[dir='rtl'] .btn-link.payment-later-link {
  margin-right: 0;
  margin-left: 24px;
}

[dir='rtl'] .message-text {
  margin-left: 0;
  margin-right: 8px;
}

[dir='rtl'] .thankyou-article-info,
[dir='rtl'] .thankyou-article-title,
[dir='rtl'] .thankyou-article-desc {
  text-align: right;
}

[dir='rtl'] .dao-container .accordion-button::after {
  margin-left: 0;
  margin-right: auto;
}

[dir='rtl'] .custom-accordion .accordion-body {
  padding: 0 0 1.5rem 2.5rem;
}

[dir='rtl'] .shipping-card-link {
  padding-left: 5px;
  padding-right: 0;
}

[dir='rtl'] .alert-dismissible {
  padding-left: 3rem;
  padding-right: 1rem;
}

[dir='rtl'] .alert.alert-popout-success {
  left: 0;
  right: auto;
}

[dir='rtl'] .exclusive-icon {
  margin-left: 1.5rem;
  margin-right: 0;
}

[dir='rtl'] .dropdown-item.disabled {
  text-align: right;
  direction: ltr;
}

[dir='rtl'] .chevron-icon {
  left: 12px;
  right: auto;
}

[dir='rtl'] .legal-id-mandatory {
  padding: 0 3px 0 0;
}

[dir='rtl'] .consent-text .legal-id-mandatory,
[dir='rtl'] .form-check .legal-id-mandatory {
  padding: 0 0 0 3px;
}
