/* Typeahead */
/* Pristine */
.form-floating>.form-control.custom-typeahead {
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
  border: 1px solid #8c8c8c;
  color: #000000;
  height: calc(3rem + 2px);
  padding: 0.75rem;
  padding-right: calc(1.5em + 0.75rem);
}

.form-control.custom-typeahead:hover {
  border-color: #1a1a1a;
}
.form-control.custom-typeahead:focus {
  border: 1.5px solid #1a1a1a;
  color: #1a1a1a;
  box-shadow: none;
}
/* new added */
.form-floating .form-control.custom-typeahead:focus,
.form-floating .form-control.custom-typeahead:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.425rem;
}
.form-control.custom-typeahead ~ label.icon-label {
  color: #666666;
  padding: 12px 0 0 55px;
}
.flag-label .form-control.custom-typeahead.icon-label {
  padding-left: 55px !important;
}
.form-control.custom-typeahead ~ label.no-icon-label
 {
  color: #666666;
  padding: 12px 0 0 12px;
}
.flag-label .form-control.custom-typeahead.no-icon-label
 {
  padding-left: 12px !important;
}
.form-control.custom-typeahead:not(:placeholder-shown) ~ label {
  font-size: 1rem;
  color: #666666;
}
.form-floating .form-control.custom-typeahead:focus ~ label.icon-label,
.form-floating .form-control.custom-typeahead:not(:placeholder-shown) ~ label.icon-label {
  opacity: 1;
  transform: scale(0.6875) translateX(27.1875px) translateY(-3.75px);
}
.form-floating .form-control.custom-typeahead:focus ~ label.no-icon-label,
.form-floating .form-control.custom-typeahead:not(:placeholder-shown) ~ label.no-icon-label {
  opacity: 1;
  transform: scale(0.6875) translateX(0.30rem) translateY(-.5rem);
}

/* Valid */
.form-control.custom-typeahead.is-valid,
.was-validated .form-control.custom-typeahead:valid {

  background-image: url('../../../assets/icons/validated_success.svg');
  background-size: calc(0.75em + 0.125rem) calc(0.75em + 0.125rem);
  border-color: #8c8c8c;
}
.form-control.custom-typeahead.is-valid:hover,
.was-validated .form-control.custom-typeahead:valid:hover {
  border-color: #1a1a1a;
}
.form-control.custom-typeahead.is-valid:focus,
.was-validated .form-control.custom-typeahead:valid:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}
/* Invalid */
.form-control.custom-typeahead.is-invalid,
.was-validated .form-control.custom-typeahead:invalid {
 
  border-color: #d40511;
  background-image: none;
}
.form-control.custom-typeahead.is-invalid:hover,
.was-validated .form-control.custom-typeahead:invalid:hover {
  border-color: #1a1a1a;
}
.form-control.custom-typeahead.is-invalid:focus,
.was-validated .form-control.custom-typeahead:invalid:focus {
  border-color: #000000;
  box-shadow: none;
}

.form-control.custom-typeahead.is-invalid:focus ~ label,
.form-control.custom-typeahead.is-invalid:not(:placeholder-shown) ~ label {
  color: #d40511;
  opacity: 1;
}
.form-control.custom-typeahead ~ .invalid-feedback {
  padding-left: 1.531rem;
  background-image: url('../../../assets/icons/validated_error.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 0.938rem;
}

/* Disabled */
.form-control.custom-typeahead:disabled {
  background-color: #ffffff;
  border-color: #8c8c8c;
  color: #1a1a1a;
  opacity: 0.5;
}
.form-floating .form-control.custom-typeahead:disabled ~ label {
  opacity: 0.5;
} 
/* Typeahead end */

/* Typeahead Icon */

.flag-icon {
  position: absolute !important;
  left: 10px;
  top: 12px;
  width: 36px;
  height: 27px;
}

.dropdown-menu{
  width: 100%;
}

.chevron-icon {
  background: transparent;
  border: 0;
  position: absolute;
  right: 12px;
  top: 12px;
}

.rbt-menu .dropdown-divider {
  display: none;
}

/* Typeahead Icon end */
