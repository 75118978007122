.card {
  border-radius: 0 !important;
  border-width: 0 !important;
}

.card.card-border {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 10px;
}

.card.no-border {
  border: none;
}

.card .body--pad-rbl-24 {
  padding: 0 24px 24px 24px;
}

.card .margin-top-24 {
  margin: 24px 0 0 0;
}

.card .btn-primary {
  margin: 24px 0 0 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card .title {
  font-weight: bold;
  font-size: 24px;
  margin: 24px 0 0 0;
}
