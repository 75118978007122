.shipping-card.shipping-border {
  border-radius: 12px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
}

.shipping-card:first-child {
  margin-left: 0.75rem;
  margin-right: 0.9375rem;
}

.shipping-card:last-child {
  margin-left: 0.9375rem;
  margin-right: 0.75rem;
}

.shipping-card-label {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 0.375rem;
}

.shipping-card-link {
  color: #d40511;
  line-height: 1.75rem;
  padding-right: 5px;
  cursor: pointer;
}

.shipping-card.no-border:first-child {
  margin-left: 0;
  margin-right: 0;
}

.shipping-card.no-border:last-child {
  margin-left: 0;
  margin-right: 0;
}

.shipping-card.no-border {
  border-radius: 0;
  box-shadow: none;
}
