/* Pristine */
.form-floating>.form-select.custom-select-field {
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  border: 1px solid #8c8c8c;
  color: #000000;
  height: calc(3rem + 2px);
  padding: 0.75rem;
  padding-right: calc(1.5em + 0.75rem);
  padding-top: 1.25rem;
  padding-bottom: 0.425rem;
  background-image: url('../../../assets/icons/chevron_down_black.svg');
  background-size: calc(0.75em + 0.125rem) calc(0.75em + 0.125rem);
}
.form-select.custom-select-field:hover {
  border-color: #1a1a1a;
}
.form-select.custom-select-field:focus {
  border: 1.5px solid #1a1a1a;
  color: #1a1a1a;
  box-shadow: none;
}
/* new added */
.form-floating .form-select.custom-select-field:focus,
.form-floating .form-select.custom-select-field:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.425rem;
}
.form-select.custom-select-field ~ label {
  color: #666666;
  padding: 0.75rem;
}
.form-select.custom-select-field:not(:placeholder-shown) ~ label {
  font-size: 1rem;
  color: #666666;
}
.form-floating .form-select.custom-select-field:focus ~ label,
.form-floating .form-select.custom-select-field:not(:placeholder-shown) ~ label {
  opacity: 1;
  transform: scale(0.6875) translateX(0.45rem) translateY(-0.25rem);
}


/* Valid */
.form-select.custom-select-field.is-valid,
.was-validated .form-select.custom-select-field:valid {
  border-color: #8c8c8c;
  background-image: none;
}
.form-select.custom-select-field.has-checkmark.is-valid,
.was-validated .form-select.custom-select-field.has-checkmark:valid {
  border-color: #8c8c8c;
  background-image: url('../../../assets/icons/validated_success.svg');
}

.form-select.custom-select-field.is-valid:hover,
.was-validated .form-select.custom-select-field:valid:hover {
  border-color: #1a1a1a;
}
.form-select.custom-select-field.is-valid:focus,
.was-validated .form-select.custom-select-field:valid:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}
.form-select.custom-select-field.is-valid:not([multiple]):not([size]),
.form-select.custom-select-field.is-valid:not([multiple])[size='1'],
.was-validated .form-select.custom-select-field:valid:not([multiple]):not([size]),
.was-validated .form-select.custom-select-field:valid:not([multiple])[size='1'] {
  background-image: url('../../../assets/icons/chevron_down_black.svg'),
  url('../../../assets/icons/validated_success.svg');
  background-size: calc(0.75em + 0.125rem) calc(0.75em + 0.125rem);
}

/* Invalid */
.form-select.custom-select-field.is-invalid,
.was-validated .form-select.custom-select-field:invalid {
  border-color: #d40511;
  background-image: none;
}
.form-select.custom-select-field.is-invalid:hover,
.was-validated .form-select.custom-select-field:invalid:hover {
  border-color: #1a1a1a;
}
.form-select.custom-select-field.is-invalid:focus,
.was-validated .form-select.custom-select-field:invalid:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}
.form-select.custom-select-field.is-invalid:not([multiple]):not([size]),
.form-select.custom-select-field.is-invalid:not([multiple])[size='1'],
.was-validated .form-select.custom-select-field:invalid:not([multiple]):not([size]),
.was-validated .form-select.custom-select-field:invalid:not([multiple])[size='1'] {
  background-image: url('../../../assets/icons/chevron_down_black.svg');
  background-size: calc(0.75em + 0.125rem) calc(0.75em + 0.125rem);
}

.form-floating .form-select.custom-select-field.is-invalid:focus ~ label,
.form-floating .form-select.custom-select-field.is-invalid:not(:placeholder-shown) ~ label {
  color: #d40511;
  opacity: 1;
}
.form-floating .form-select.custom-select-field ~ .invalid-feedback {
  padding-left: 1.531rem;
  background-image: url('../../../assets/icons/validated_error.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 0.938rem;
}

/* Disabled */
.form-floating .form-select.custom-select-field:disabled {
  background-color: #ffffff;
  border-color: #8c8c8c;
  color: #1a1a1a;
  opacity: 0.5;
}

.form-floating .form-select.custom-select-field:disabled ~ label {
  opacity: 0.5;
} 
