.custom-modal.modal-dialog {
  width: 41.875rem;
  max-width: none;
}

.custom-modal .modal-content {
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  padding: 2.5rem;
}

.custom-modal .modal-header {
  border-bottom: none;
  padding: 0;
}

.custom-modal .modal-title {
  color: #1a1a1a;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.custom-modal .modal-body {
  color: #1a1a1a;
  line-height: 1.75rem;
  padding: 1rem 0 2.5rem 0;
}

.custom-modal .modal-footer {
  border-top: none;
  padding: 0;
}

.custom-modal .custom-modal-link {
  text-decoration: none;
  color:  #d40511;
}

.custom-modal .custom-modal-link:hover {
  color: #d40511;
  box-shadow: unset !important;
}
