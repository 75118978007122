.alert.alert-popout-success {
  width: 22.5rem;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: flex-start;
  margin: 1.5rem;
  color: #000000;
  border: none;
  border-radius: 0;
  line-height: 19.2px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 4px 14px 0px rgba(0, 0, 0, 0.10), 0px 1px 4px -1px rgba(0, 0, 0, 0.10);;
  animation: 0.5s ease 0s 1 slideInFromRight;
}

.alert.alert-popout-success img {
  width: 2rem;
  height: 2rem;
}

.alert.alert-popout-success .btn-close {
  background-image: url(../../../assets/icons/cancel.svg);
  opacity: 1;
  padding: 1rem;
  box-shadow: none;
}
