.custom-accordion.accordion-flush .accordion-item,
.custom-accordion.accordion-flush .accordion-item:last-child {
  border-bottom: 1px solid #8c8c8c;
}

.custom-accordion .accordion-button,
.custom-accordion .accordion-button:focus,
.custom-accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: #000000;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.5rem 0.375rem 1.5rem 0;
}

.custom-accordion .accordion-button::after,
.custom-accordion .accordion-button:not(.collapsed)::after {
  background-image: url('../../../assets/icons/chevron_down_red.svg');
  background-size: 1.25rem 0.75rem;
  height: 0.75rem;
  width: 1.25rem;
}

.custom-accordion .accordion-body {
  color: #333333;
  font-weight: 400;
  padding: 0 2.5rem 1.5rem 0;
}
