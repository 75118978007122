/* Typeahead */

/* Pristine */
.form-floating>.form-control.async-typeahead {
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
  border: 1px solid #8c8c8c;
  color: #1a1a1a;
  height: calc(3rem + 2px);
  padding: 0.75rem;
  padding-right: calc(1.5em + 0.75rem);
}

.form-control.async-typeahead:hover {
  border-color: #1a1a1a;
}

.form-control.async-typeahead:focus {
  border: 1.5px solid #1a1a1a;
  color: #1a1a1a;
  box-shadow: none;
}

/* new added */
.form-floating .form-control.async-typeahead:focus,
.form-floating .form-control.async-typeahead:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.425rem;
}

.form-control.async-typeahead ~ label {
  color: #666666;
  padding: 0.75rem;
}

.form-control.async-typeahead:not(:placeholder-shown) ~ label {
  font-size: 1rem;
  color: #666666;
}

.form-floating .form-control.async-typeahead:focus ~ label,
.form-floating .form-control.async-typeahead:not(:placeholder-shown) ~ label {
  opacity: 1;
  transform: scale(0.6875) translateX(0.45rem) translateY(-0.25rem);
}

/* Valid */
.form-control.async-typeahead.is-valid,
.was-validated .form-control.async-typeahead:valid {

  background-image: url('../../../assets/icons/validated_success.svg');
  background-size: calc(0.75em + 0.125rem) calc(0.75em + 0.125rem);
  border-color: #8c8c8c;
}

.form-control.async-typeahead.is-valid:hover,
.was-validated .form-control.async-typeahead:valid:hover {
  border-color: #1a1a1a;
}

.form-control.async-typeahead.is-valid:focus,
.was-validated .form-control.async-typeahead:valid:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}

/* Invalid */
.form-control.async-typeahead.is-invalid,
.was-validated .form-control.async-typeahead:invalid {
 
  border-color: #d40511;
  background-image: none;
}

.form-control.async-typeahead.is-invalid:hover,
.was-validated .form-control.async-typeahead:invalid:hover {
  border-color: #1a1a1a;
}

.form-control.async-typeahead.is-invalid:focus,
.was-validated .form-control.async-typeahead:invalid:focus {
  border-color: #000000;
  box-shadow: none;
}

.form-control.async-typeahead.is-invalid:focus ~ label,
.form-control.async-typeahead.is-invalid:not(:placeholder-shown) ~ label {
  color: #d40511;
  opacity: 1;
}

.form-control.async-typeahead ~ .invalid-feedback {
  padding-left: 1.531rem;
  background-image: url('../../../assets/icons/validated_error.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 0.938rem;
}

/* Disabled */
.form-control.async-typeahead:disabled {
  background-color: #ffffff;
  border-color: #8c8c8c;
  color: #1a1a1a;
  opacity: 0.5;
}

.form-floating .form-control.async-typeahead:disabled ~ label {
  opacity: 0.5;
} 

/* Typeahead Icon */
.flag-icon {
  position: absolute !important;
  left: 10px;
  top: 12px;
  width: 36px;
  height: 27px;
}

.flag-label .form-control.async-typeahead {
  padding-left: 55px !important;
}
 
/* Typeahead Icon end */
/* Typeahead end */
