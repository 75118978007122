.payment-header {
  font-weight: 800;
  margin-bottom: 16px;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.message-text {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.card-message {
  display: flex;
  margin-top: 20px;
}

.message-text.success {
  color: #76bd22;
}

.card-message .failure,
.card-message .failure:hover,
.message-text.btn-link {
  color: #d40511;
}

.align-end {
  justify-content: flex-end;
}

.payment-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.btn-link.payment-later-link {
  color: #d40511;
  text-decoration: none;
  margin-right: 24px;
}

.btn-link.payment-later-link:hover {
  color: #d40511;
}

.iframe-container {
  height: auto;
  position: relative;
}

.iframe-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
