.file-type-img {
  margin-left: 0;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
}

[dir='rtl'] .file-type-img {
  margin-left: 1rem;
  margin-right: 0;
  width: 2rem;
  height: 2rem;
}
