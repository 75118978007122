.thankyou-content {
    background-image: linear-gradient(90deg, #ffcc00 0%, #fff0b1 100%);
}

.thankyou-title {
    color: #000000;
    font-size: 2.5rem;
    line-height: 2.7rem;
    font-weight: 800;
    margin:1.5rem auto;
    text-transform: uppercase;
}

.thankyou-subtitle {
    color: #000000;
    font-size: 1.5rem;
    max-width: 545px;
    margin: 0 auto 10px;
}

.thankyou-subtitle_2 {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
}

.thankyou-dao-contactus {
    max-width: 500px;
    margin:1.5rem auto 2.5rem;
}

.thankyou-title a,
.thankyou-subtitle a,
.thankyou-subtitle_2 a,
.thankyou-conventional-title a,
.thankyou-conventional-subtitle a,
.thankyou-dao-contactus a {
    color:#D40511
}

.thankyou-action-wrap {
    margin: 40px auto;
}

.thankyou-img-contact {
    max-width: 100%;
    max-height: 21.875rem;
}

.thankyou-mail-icon {
    position: absolute;
    top: 30%;
    left: 65%;
    width: 17.5%;
}

.thankyou-phone-icon {
    position: absolute;
    top: 20%;
    left: 12.5%;
    width: 17.5%;
}

.thankyou-check-green-icon {
    position: absolute;
    top: 20%;
    left: 12.5%;
    width: 17.5%;
}

.thankyou-card-wrap {
    background-color: #F2F2F2;
}

.thankyou-card-header,
.thankyou-article-header
 {
    margin:16px 0 40px;
    font-size: 32px;
    font-weight: 800;
    color: #000000;
}

.thankyou-card-image {
    height:62px;
}

.thankyou-card-title {
    margin:16px 0 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    color: #D40511;
}

.thankyou-card-desc {
    margin: 5px 0 0;
    font-size: 16px;
}


.thankyou-conventional-title {
    color: #000000;
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 800;
    max-width: 900px;
    margin:0 auto;
    text-transform: uppercase;
}

.thankyou-conventional-title.service-unavailable {
    font-size: 2.28rem;
    margin-bottom: 20px;
}

.thankyou-conventional-subtitle {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 400;
    max-width: 700px;
    margin:24px auto 40px;
}

.thankyou-conventional-desc {
      margin:0px auto 40px;
}

.thankyou-article-image {
    width: 100%;
}

.thankyou-article-title {
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0px;
    line-height: 30px;
}

.thankyou-article-info {
    text-align: left;
    font-size: 16px;
    margin: 15px 0 5px 0;
}

.thankyou-article-info span {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 0px;
}

.thankyou-article-desc {
    margin-top: 15px;
    text-align: left;
    font-size: 16px;
}

.thankyou-card {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.thankyou-card .shadow {
    border-top: 10px solid #FFCC00;
}

.thankyou-card:hover {
    color: #000;
    text-decoration: none;
}

.thankyou-article-link {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    display: block;
}

.thankyou-article-link:hover {
    color: #000000;
}

.thankyou-page-section1 {
    margin-top: 2.5rem;
}
