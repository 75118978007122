.content {
  display: flex;
  justify-content: center;
  min-height: 60vh;
}

.content-title {
  font-size: 2.75rem;
  font-stretch: condensed;
  font-weight: 800;
  margin: 2rem 0 0;
  color: #000000;
  line-height: 2.75rem;
}

.company-mb-2 {
  margin-bottom: 2rem;
}

.content-subtitle {
  font-size: 1rem;
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.stepper-wrapper {
  background: #f2f2f2;
}
