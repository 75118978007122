.announcement-box {
  padding: 0 7.375rem;
}
.announcement-box,
.alert.announcement {
  background-color: #d40511;
}
.alert.announcement .btn-close {
  opacity: 1;
}
.alert.announcement .btn-close:focus {
  box-shadow: none;
  outline: 2px solid #000000;
}
