.exclusive-container {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 7.375rem 4rem;
}

.exclusive-title {
    font-size: 2rem;
    font-stretch: condensed;
    font-weight: 800;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #000000;
}

.exclusive-text {
    text-align: center;
    margin: 0 auto 2.5rem auto;
    font-size: 1.25rem;
    color: #000000;
}

.benefits-title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    color: #000000;
}

.benefits-text {
    line-height: 120%;
    color: #000000;
}

.exclusive-icon {
    background: #FFCC00;
    border-radius: 50%;
    width: 92px;
    height: 92px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    margin-right: 1.5rem;
}

.exclusive-icon img {
    width: 51px;
    height: 51px;
}

.exclusive-img {
    width: 100%;
    height: auto;
}

.account-banner {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    padding: 6.25rem 7.375rem;
}

.banner-title {
    color: #ffffff;
    font-size: 45px;
    font-stretch: condensed;
    font-weight: 800;
    text-transform: uppercase;
    width: 36.625rem;
    line-height: 1;
    max-width: 750px;
}

.banner-text {
    color: #ffffff;
    width: 60%;
    max-width: 750px;
    font-size: 1.4rem;
    margin: 1rem 0 1.5rem 0;
}
