.file-upload-container {
  border: 1px solid #cccccc;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  align-items: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.file-upload-document-container {
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  padding: 1.25rem;
}

.file-upload-document-container a {
  word-break: break-word;
}

.file-upload-link {
  color: #d40511;
  cursor: pointer;
}

.file-upload-size {
  color: #666666;
  display: flex;
  font-size: 14px;
  line-height: 16px;
}
