/* Button */
/* Primary */
.btn-primary {
  background-color: #d40511e3;
  border-radius: 0.25rem;
  font-weight: 700;
  border: none;
}
.btn-primary:hover {
  background-color: #fa151f;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: #fa151f;
  box-shadow: none;
  outline: 2px solid #000000;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  outline: 2px solid #000000;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #d1d1d1;
  opacity: 1;
}
/* Secondary */
.btn-secondary {
  color: #d40511;
  background-color: #fafafa;
  font-weight: 700;
  border: 1px solid #d40511 !important;
}
.btn-secondary:hover {
  color: #f91320;
  background-color: #ffffff;
  border-color: #f91320;
  box-shadow: unset !important;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #f91320;
  background-color: #ffffff;
  box-shadow: none;
  outline: 2px solid #f91320;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: 1px solid #d40511e3;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-radius: 3px;
  border: 1px solid #dcdcdc !important;
  background-color: #fafafa;
  box-sizing: border-box;
  color: #a3a3a3;
  opacity: 1;
}

.btn-secondary-border:focus,
.btn-secondary-border:active {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #d40511;
}

.btn-secondary.file-upload-button {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.375rem 1.5rem;
}

.btn-back {
  font-weight: 400;
  color: #d40511;
  cursor: pointer;
}

.btn-skip {
  position: absolute;
  z-index: 999;
  display: none;
  opacity: 0;
}

.btn-skip:focus {
  display: block;
  opacity: 1;
}
/* Button end */
